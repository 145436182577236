import React from 'react';
import { Transition, Checkbox } from 'semantic-ui-react';
import { formatDate } from 'util/format/DateTime';
import { useCreditsContext } from 'design/molecules/CreditsProvider';
import CreditsIcon from 'design/atoms/CreditsIcon';
import { ShoppingCartSegment } from '../shared/ShoppingCart';
import useUser, { useRootUser } from 'util/useUser';



const CreditsConfirmAccountantRole = ({ role, hasConfirmedRole, onConfirmRole }) => {

    
    const user = useUser();
    const { credits, loading } = useCreditsContext();

    if (credits >0) {
        return;
    }

    return (
        <Transition visible={!loading}>
            <ShoppingCartSegment title='Bekræft rolle' icon='University'>
                <p>
                    <Checkbox 
                        label={{ children: <span>Jeg bekræfter herved at jeg har valgt rollen <strong>{role}</strong>.</span> }}
                        checked={hasConfirmedRole} 
                        onChange={(e, data) => onConfirmRole(data.checked)}
                    />
                </p>
                
                {credits <= 0 && (
                    <>
                    <p>
                        Rollen fastsættes ved dette køb,
                        og vil herefter ikke kunne ændres.
                    </p>

                    
                    </>
                )}

                {credits > 0 && (
                    <p>
                        Dine nuværende <strong><CreditsIcon orange /> × {credits}</strong> credits
                        {' '}vil også få deres nuværende udløbsdato rykket til ovenstående dato.
                    </p>
                )}
            </ShoppingCartSegment>
        </Transition>
    );
};

export default CreditsConfirmAccountantRole;