import React, { useState } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { makePurchase } from 'http/payment';
import { getCardToken } from 'util/stripe';
import handleSCA from 'util/Stripe/handleSCA';
import i18n from 'i18n/pages/Payment';
import BankTransferPurchase from './BankTransferPurchase';
import CardForm from './CardForm';
import { doPriceCalculations } from './doPriceCalculations';

function preparePurchaseFromOrderLines(orderLines) {
    const purchaseDetails = {
        products: [],
        addonProductPurchases: [],
        creditsToBuy: 0,
    };

    for (const orderLine of orderLines) {
        const { productPurchase, addonProductPurchase, amountOfCredits } = orderLine.metadata;

        if (productPurchase) {
            purchaseDetails.products.push(productPurchase);
        }

        if (addonProductPurchase) {
            purchaseDetails.addonProductPurchases.push(addonProductPurchase);
        }

        if (amountOfCredits) {
            purchaseDetails.creditsToBuy += amountOfCredits;
        }
    }
    
    return purchaseDetails;
}

async function doPurchase({ stripe, elements, sessionToken, purchaseDetails, selectedPackageTier, isBankTransferPurchase }) {
    try {
        let cardToken;

        if (!isBankTransferPurchase) {
            const { token, error } = await getCardToken(stripe, elements);

            if (error) {
                return {
                    success: false,
                    message: error.message,
                };
            }

            cardToken = token.id;
        }
        
        const purchase = {
            cardToken,
            sessionToken,
            selectedPackageTier,
            ...purchaseDetails,
        };

        const result = await makePurchase(purchase);
        const scaResult = await handleSCA(stripe, result);

        if (!scaResult.success) {
            return {
                success: false,
                message: scaResult.message,
            };
        }

        return {
            success: true,
            paymentIntentID: result.paymentIntentID,
        };
    } catch (e) {
        const msg = i18n.stripeErrorResponses[e.i18nKey] || i18n.unknownError;

        return {
            success: false,
            message: msg,
        };
    }
}

const usePaymentState = ({ selectedPackageTier, discount, orderLines = [] }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [sessionToken] = useState(window.btoa(Math.random()));
    const [error, setError] = useState(null);
    const [working, setWorking] = useState(false);
    const [cardStatus, setCardStatus] = useState({});
    const [paymentDone, setPaymentDone] = useState(false);

    const { total: totalPrice } = doPriceCalculations({ orderLines, discount });
    const isBankTransferPurchase = totalPrice >= 20_000;
    const canPay = isBankTransferPurchase ? true : (totalPrice > 0 && !!cardStatus.ready);

    const renderPaymentMethod = () => {
        if (isBankTransferPurchase) {
            return (
                <BankTransferPurchase />
            );
        }

        return (
            <CardForm
                working={working}
                cardStatus={cardStatus}
                setCardStatus={setCardStatus}
                onMounted={() => setCardStatus({})}
            />
        );
    };

    const renderPayButton = (onClick = performPaymentRequest, disabled=false) => {
        return (
            <Button
                primary
                fluid
                disabled={!canPay || working || disabled}
                loading={working}
                onClick={onClick}
                content={isBankTransferPurchase ? 'Betal med bankoverførsel' : 'Betal med kort'}
            />
        );
    };

    const renderError = () => {
        if (!error) {
            return null;
        }

        return (
            <Message error>
                <Icon name='warning circle' />
                {error}
            </Message>
        );
    };

    const performPaymentRequest = async () => {
        setWorking(true);
        setError(null);

        const purchaseDetails = preparePurchaseFromOrderLines(orderLines);

        const purchaseResult = await doPurchase({
            stripe,
            elements,
            sessionToken,
            purchaseDetails,
            selectedPackageTier,
            isBankTransferPurchase,
        });

        if (!purchaseResult.success) {
            setError(purchaseResult.message);
            setWorking(false);
        } else {
            setPaymentDone(true);
        }

        return purchaseResult;
    };

    return {
        performPaymentRequest,
        renderPaymentMethod,
        renderPayButton,
        renderError,

        error,
        working,
        paymentDone,
        canPay,
    };
};

export default usePaymentState;