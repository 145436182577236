import React from 'react';
import get from 'lodash.get';
import {
    Container,
    Segment,
    Grid,
    Message,
    Loader,
    Header,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import i18n from 'i18n/pages/AccountantOverview';
import { getClientInfo } from 'http/productEngine';
import getProducts from 'util/getProducts';
import { isPrivat, isErhverv, isAdmin } from 'util/userMethods';
import { withRootUserData } from 'util/withUserData';
import { withPersistedState } from 'util/usePersistedState';
import AccountSuspendedMessage from 'design/molecules/AccountSuspendedMessage';
import { SPLIT_BOOKKEEPER_ACCOUNTANT } from 'flags';

import { Modal, Button } from 'semantic-ui-react';
import { registerSubRole } from 'http/accounts';
import { refreshTokens } from 'network/fetch/refreshTokens';

import SubscriptionPackageSelector from '../../atoms/SubscriptionPackageSelector/SubscriptionPackageSelector';
import { getBalance } from 'http/credits';

import styles from '../AccountantOverview/styles.module.scss';

const ICON_PRIVATE = 'user';
const ICON_BUSINESS = 'building';

class AccountantRoleSelection extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            clientsInfo: {},
            products: {},
            loading: true,
            error: false,
            working: false,
            errorMsg: '',
            filterQuery: '',
            activeModal: null,
            akademiDefault: false,
            selectedRole: null,
            showConfirmationModal: false,
            pendingRoleSelection: null,
        };
        this.ACCOUNTANT_ERP_COLUMN = isAdmin(props.userData);
        this.CLIENT_TYPES = [
            {
                type: 'COMPANY',
                text: 'Selskab',
                header: 'Opret selskab',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: i18n.firstClientTeaserCompany,
            },
            {
                type: 'FIRM',
                text: 'Virksomhed',
                header: 'Opret virksomhed',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: i18n.firstClientTeaserFirm,
            },
            {
                type: 'IS',
                text: 'Interessentskab',
                header: 'Opret interessentskab',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: 'Personligt ejet I/S',
            },
            {
                type: 'GREENLAND',
                text: 'Grønlandsk selskab/virksomhed',
                header: 'Opret grønlandsk selskab/virksomhed',
                isPrivate: false,
                icon: ICON_BUSINESS,
                tooltipText: 'Firmaer med grønlandsk postnr.',
            },
            {
                type: 'PRIVATE',
                text: 'Boligudlejer',
                header: 'Opret boligudlejer',
                isPrivate: true,
                icon: ICON_PRIVATE,
                tooltipText: i18n.firstClientTeaserPrivat,
            },
        ];
    }

    fetchRequiredData = async () => {
        const [clientsInfo, products, creditBalance] = await Promise.all([
            getClientInfo(),
            getProducts(),
            getBalance(),
        ]);
        this.setState({
            clientsInfo,
            products,
            creditBalance,
        });
    };

    componentDidMount = () => this.loadData();

    loadData = async () => {
        try {
            this.setState({
                loading: true,
            });
            await this.fetchRequiredData();
            this.setState({
                loading: false,
            });
        } catch (e) {
            this.setState({
                loading: false,
                error: true,
            });
        }
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.userData?.id !== prevProps.userData?.id) {
            this.loadData();
        }
    };

    registerRole = async (packageId) => {
        console.log("Calling registerSubRole with: ", packageId);
        try {
            const result = await registerSubRole({selectedRole: packageId});
            console.log("Result is:", result);
            await refreshTokens();
            return result;
        } catch (error) {
            console.error("Caught error:", error.message || error);
            return "Fail"; 
        }
    }

    handleSplitRoleSelection = (selectedRole) => {
       
        this.setState({
            showConfirmationModal: false,
            pendingRoleSelection: selectedRole,
        });

        const result = this.registerRole(selectedRole);
        this.selectedRole = selectedRole;
        
        result.then(resolvedValue => {
            console.log("Result from registerRole:", resolvedValue);
        }).catch(error => {
            console.error("Error occurred in Promise:", error);
        });

        this.props.nextStep();
    };

    confirmRoleSelection = async () => {
        const { pendingRoleSelection } = this.state;
    
        if (pendingRoleSelection) {
            const result = await this.registerRole(pendingRoleSelection);
            this.setState({ showConfirmationModal: false, pendingRoleSelection: null });
            this.props.nextStep();
        }
    };
    
    cancelRoleSelection = () => {
        this.setState({
            showConfirmationModal: false,
            pendingRoleSelection: null,
        });
    };

    renderConfirmationModal = () => {
        const { showConfirmationModal, pendingRoleSelection, creditBalance } = this.state;

        const isExistingCustomer = creditBalance > 0;
    
        const modalContent = isExistingCustomer
        ? (
            <>
                Du er ved at sætte rollen for din konto.<br />
                Vær opmærksom på, at dette kun skal gøres én gang og vil træde i kraft med det samme.<br />
                Vi konverterer dine nuværende {creditBalance} credits til den nye rolle uden merberegning.
                <br /><br />
                Ønsker du at sætte din rolle nu?
            </>
        )
        : (
            <>
                Du er ved at sætte rollen for din konto.<br />
                Vær opmærksom på, at dette kun skal gøres én gang og kan ikke ændres, efter du har købt dine første credits.
                <br /><br />
                Vælg din rolle nu og kom i gang med Digital Revisor.
            </>
        );

        return (
            <Modal open={showConfirmationModal} size="small">
                <Modal.Header>Bekræft dit rollevalg.</Modal.Header>
                <Modal.Content>
                    <p>{modalContent}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.cancelRoleSelection} negative>Nej</Button>
                    <Button onClick={this.confirmRoleSelection} positive>Ja</Button>
                </Modal.Actions>
            </Modal>
        );
    };

    // ========================================================================================================

    renderSplitRoleSegment = () => {  

        const user = this.props.userData;
        
        const isBookkeeper = (user.roles.indexOf("bookkeeper") > -1);
        const isassistance = (user.roles.indexOf("assistance") > -1);    
        const { selectedRole } = this.state; 
        const role = selectedRole || (isassistance ? "assistance" : isBookkeeper ? "bookkeeper" : null);
        

        const tiers = [
            {
                id: 'bookkeeper',
                title: { header: 'Rapport', subheader: 'For Bogholdere og Regnskabskonsulenter' },
                semanticIcon: 'user',
                //description: 'A basic subscription package',
                price: 'Bogholder',
                lockedFeatures: [
                    'Planlægning og udførsel',
                    'Dokumentation og konklusion',
                    'FSR standard Erklæringer',
                    'Ledelsens regnskabserklæring',
                    'Avancerede funktioner',
                    'Åben API',
                ], 
                splitFeatures: [
                    'Rapport',
                    'Erklæringer',
                ], 
            },
            {
                id: 'assistance',
                title: { header: 'Rapport & Erklæring', subheader: 'For revisorer' },
                semanticIcon: 'building',
                //description: 'A premium subscription package',
                price: 'revisor',
                
                isUpgrade: true,
                isChangingPackage: false,
                lockedFeatures: [],
                splitFeatures: [
                    'Rapport',
                    'Erklæringer',
                ], 
            },
        ];

        const features = [
            'Rapport',
            'Årsrapport',
            'Digital underskrift',
            'Indberetning',
            'Afslutningsark med AI mapning',
            'ESG rapport',
            'Erklæringer',
            'Planlægning og udførsel',
            'Dokumentation og konklusion',
            'FSR standard Erklæringer',
            'Ledelsens regnskabserklæring',
            'Avancerede funktioner',
            'Åben API',
        ];

        return <SubscriptionPackageSelector
            vat={true}
            chosenPackage={role}
            isChangingPackage={true}
            buttonText = "Vælg"
            buttonChoosenText = "Valgt"
            packageSet={{ tiers, features }}
            onPackageSelected={
                (_, id) => {this.handleSplitRoleSelection(id);}
            }
    />
    };
    // ========================================================================================================




    renderNoConnectedClientsSegment = () => {

        const user = this.props.userData;
        const { selectedRole } = this.state;

        const isBookkeeper = (user.roles.indexOf("bookkeeper") > -1);
        const isassistance = (user.roles.indexOf("assistance") > -1);

        let content;
        if (user.isEntityAccountant()) {  
            content = (
                <>
                    <Container textAlign='center'>
                    {this.renderSplitRoleSegment()}
                    </Container>
                    <br />
                </>
            );
        } else {
            content = (
                <div style={{ textAlign: 'center' }}>
                    <h3>Der er ingen klienter at vise...</h3>
                </div>
            );
        }

        return (
            <Segment placeholder className={styles.noClientsBox}>
                {content}
            </Segment>
        );
    };

    renderAccountSuspended = () => {
        return <AccountSuspendedMessage />;
    };

    renderContent = () => {
        const { error, loading } = this.state;

        if (loading) {
            return <Segment basic textAlign='center'>
                <Loader inline active size='huge' />
            </Segment>;
        };

        let content;
        if (error) {
            /* i18n */
            content = <Message
                content= {i18n.errorGettingListOfClients}
                icon='warning circle'
                warning
            />;
        } else {
            //const clients = this.formatClientsData();
            content = <>
                {this.renderAccountSuspended()}
                {this.renderNoConnectedClientsSegment()}
            </>;
        }

        return (
            <Container className={styles.container}>
            <Segment padded>
                {content}
            </Segment>
                <Grid columns='equal' verticalAlign='middle'>
                    <Grid.Column>
                        <Header
                            as='h2'
                            //content='Rolle valg'
                            //subheader='Inden du går igang skal du tage stilling til hvilken rolle, og dermed hvilke funktionaliteter, du ønsker at have fremadrettet'
                            subheader=''
                        />
                    </Grid.Column>
                </Grid>
            </Container>
        );


    };

    render () {
        return (
            <>
                {this.renderContent()}
                {this.renderConfirmationModal()}
            </>
        );
    }
}

export default withRouter(
    withRootUserData(
        withPersistedState(AccountantRoleSelection, 'filterClientsForm', {
            pickedStatuses: [],
            pickedTaxYears: [],
            onlyDisplayAssignedClients: false,
        }),
    ),
);