import React from 'react';
import { Button, Icon, Menu, Modal, Popup } from 'semantic-ui-react';
import { CLOSING_SHEET_QUESTIONS } from 'flags';
import { formatDateTime } from 'util/format/DateTime';
import EntriesViewer from './EntriesViewer';
import AttachmentsViewer from './AttachmentsViewer';
import CommentsViewer from './CommentsViewer';
import QuestionsViewer from './QuestionsViewer';

export const accountOptionsCategories = {
    postings: {
        title: 'Posteringer',
        indicatorTitle: {
            singular: 'efterpostering',
            plural: 'efterposteringer',
        },
        icon: 'list',
        Component: EntriesViewer,
        isVisible: () => true,
    },
    attachments: {
        title: 'Bilag',
        indicatorTitle: {
            singular: 'bilag',
            plural: 'bilag',
        },
        icon: 'attach',
        Component: AttachmentsViewer,
        isVisible: () => true,
    },
    notes: {
        title: 'Noter',
        indicatorTitle: {
            singular: 'note',
            plural: 'noter',
        },
        icon: 'sticky note',
        Component: CommentsViewer,
        isVisible: () => true,
    },
    questions: {
        title: 'Spørgsmål',
        indicatorTitle: {
            singular: 'Spørgsmål',
            plural: 'Spørgsmål',
        },
        icon: 'question circle',
        Component: QuestionsViewer,
        isVisible: account => CLOSING_SHEET_QUESTIONS() && account.associatedQuestionsGroup?.questionsAndAnswers?.length > 0,
    },
};

export const getAccountOptionsMenuItems = (account) => {
    return Object.values(accountOptionsCategories).filter(category => {
        return category.isVisible(account);
    });
};

const AccountOptions = ({
    header,
    onClose,
    activeMenuItemIndex,
    setActiveMenuItemIndex,
    showDecimalAccounts,
    menuItemsWithActivity,
    currentYearAmount,
    unroundedCurrentYearAmount,
    auditorsDocumentationInfo,
    ...props
}) => {
    const accountOptionMenuItems = getAccountOptionsMenuItems(props.account);

    const renderMenu = () => (
        <Menu
            tabular
            attached='top'
            style={{
                marginBottom: '-1px',
                marginTop: '1em',
            }}
        >
            {accountOptionMenuItems.map(({ title, icon, Component }, i) => {
                return (
                    <Menu.Item
                        content={title}
                        active={i === activeMenuItemIndex}
                        onClick={() => setActiveMenuItemIndex(i)}
                        link={i !== activeMenuItemIndex}
                        icon={
                            <Icon
                                name={icon}
                                color={menuItemsWithActivity.has(Component) ? 'green' : 'black'}
                            />
                        }
                    />
                );
            })}
        </Menu>
    );

    const renderApprovalButton = () => {
        const { associatedQuestionsGroup } = props.account;
        if (!associatedQuestionsGroup) {
            return null;
        }

        if (!auditorsDocumentationInfo.loggedInUserIsConfirmingUser) {
            return null;
        }

        const prerequisitesForEnablingConfirmButton = [];

        const uncheckedRelevantAccounts = associatedQuestionsGroup.countRelevantAccountsThatAreUnchecked();
        if (uncheckedRelevantAccounts > 0) {
            prerequisitesForEnablingConfirmButton.push('Alle konti i gruppen er afstemt')
        }
        
        const amountOfUnansweredQuestions = associatedQuestionsGroup.countUnansweredQuestions();
        if (amountOfUnansweredQuestions > 0) {
            prerequisitesForEnablingConfirmButton.push('Alle spørgsmål er besvaret');
        }

        const { isApprovedByConfirmingLogin, confirmingLoginApprovalTimestamp } = associatedQuestionsGroup.state || {};

        let buttonTooltip;
        if (isApprovedByConfirmingLogin) {
            buttonTooltip = `Godkendt d. ${formatDateTime(confirmingLoginApprovalTimestamp)}`
        } else if (prerequisitesForEnablingConfirmButton.length > 0) {
            buttonTooltip = (
                <div>
                    Du kan først godkende når:
                    {prerequisitesForEnablingConfirmButton.map(message => {
                        return <div>&bull; {message}</div>;
                    })}
                </div>
            );
        }

        const buttonEnabled = isApprovedByConfirmingLogin || prerequisitesForEnablingConfirmButton.length === 0;

        return (
            <Popup
                basic
                position='left center'
                disabled={!buttonTooltip}
                content={buttonTooltip}
                trigger={(
                    <div>
                        <Button
                            icon={isApprovedByConfirmingLogin ? 'check' : undefined}
                            onClick={() => props.toggleQuestionGroupChecked(associatedQuestionsGroup.groupID)}
                            content={isApprovedByConfirmingLogin ? `Godkendt` : 'Godkend'}
                            basic={isApprovedByConfirmingLogin}
                            disabled={!buttonEnabled}
                            primary
                        />
                    </div>
                )}
            />
        );

    };

    const activeItem = accountOptionMenuItems[activeMenuItemIndex];
    const Component = activeItem?.Component;

    return (
        <Modal open onClose={onClose} closeIcon centered={false} closeOnDimmerClick={false}>
            <Modal.Header style={{ paddingBottom: 0 }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        {header}
                    </div>
                    {renderApprovalButton()}
                </div>
                {renderMenu()}
            </Modal.Header>
            {Component && <Component {...props} showDecimalAccounts={showDecimalAccounts} currentYearAmount={props.account.currentYearAmount} unroundedCurrentYearAmount={props.account.unroundedCurrentYearAmount}/>}
        </Modal>
    );
};

export default AccountOptions;