import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Header, Icon, Input, Loader, Modal, Popup, Segment, Transition } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as fileStorage from 'http/file-storage';
import { TAX_REPORTED_TAG } from 'util/FactMapUtil';
import { downloadText } from 'util/downloadBlob';
import { readAsBase64 } from 'util/files';
import reportEditor from 'http/reportEditor';
import ColoredText from 'design/atoms/ColoredText';
import UploadLogo from 'design/molecules/UploadLogo';

const ReportThemeSettingsModal = ({ onClose, onSave, isLocked }) => {
    const [theme, setTheme] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        reportEditor.getUserDefinedTheme()
            .then(theme => setTheme(theme || {}))
            .finally(() => setLoading(false));
    }, []);

    const handleClose = async () => {
        await reportEditor.putUserDefinedTheme(theme);
        onSave();
        onClose();
    };

    const updateTheme = (updator = {}) => {
        setTheme({
            ...theme,
            ...updator,
        });
    };

    const updateThemeColor = (colorKey, colorValue) => {
        const currentColors = theme.colors || {};
        updateTheme({
            colors: {
                ...currentColors,
                [colorKey]: colorValue,
            },
        });
    };

    const validateHexColor = (hexColor) => {
        if (!hexColor) return true;
        return /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexColor);
    };

    return (
        <Modal open onClose={handleClose} closeIcon>
            <Modal.Header>
                <Icon name='cog' />
                Rapportindstillinger
            </Modal.Header>
            <Modal.Content style={{ cursor: isLocked ? 'not-allowed' : undefined }}>
                {loading && <Loader inline='centered' active />}
                {!loading && (
                    <Form style={{ opacity: isLocked ? 0.75 : 1, pointerEvents: isLocked ? 'none' : undefined }}>
                        <Form.Group widths='equal'>
                            <Form.Field error={!validateHexColor(theme.colors?.primary)}>
                                <label>Primær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('primary', value)}
                                    defaultValue={theme.colors?.primary}
                                    error={!validateHexColor(theme.colors?.primary)}
                                    placeholder='Primær mærkefarve...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.secondary)}>
                                <label>Sekundær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('secondary', value)}
                                    defaultValue={theme.colors?.secondary}
                                    placeholder='Sekundær mærkefarve...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.tertiary)}>
                                <label>Tertiær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('tertiary', value)}
                                    defaultValue={theme.colors?.tertiary}
                                    placeholder='Tertiær mærkefarve...'
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Font</label>
                            <Dropdown
                                selection
                                clearable
                                placeholder='Overwrite the default font...'
                                defaultValue={theme?.fontAssetID}
                                onChange={(_, { value }) => updateTheme({ fontAssetID: value })}
                                // matches fonts defined in the report-editor service at:
                                // src/routes/assets/static/'
                                options={[
                                    { text: 'Arial', value: 'font_arial.ttf' },
                                    { text: 'Open Sans', value: 'font_open_sans.ttf' },
                                    { text: 'Roboto', value: 'font_roboto.ttf' },
                                    { text: 'Segoe UI', value: 'font_segoe_ui.ttf' },
                                    { text: 'Tinos', value: 'font_tinos.ttf' },
                                ]}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Logo</label>
                            <UploadLogo />
                        </Form.Field>
                    </Form>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='black'
                    content='Luk'
                    onClick={handleClose}
                />
            </Modal.Actions>
        </Modal>
    );
};

const ReportOptionButton = ({ onClick, content, icon, tooltip, ...props }) => {
    const button = (
        <div
            style={{
                border: '1px solid rgb(190, 190, 190)',
                borderRadius: '0.5em',
                padding: '0.25em 1em',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <ColoredText
                iconPosition='left'
                content={content}
                icon={icon}
                underlined={false}
                link
                {...props}
            />
        </div>
    );

    return (
        <Popup
            disabled={!tooltip}
            trigger={button}
            content={tooltip}
            position='left center'
        />
    );
};

const FinishReportButton = ({ onConfirm }) => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    return (
        <>
            <ReportOptionButton
                content='Færdiggør rapport'
                icon={<Icon name='check circle' color='green' />}
                onClick={() => setConfirmationModalOpen(true)}
                tooltip='Når din rapport er færdig kan du låse den her'
            />
            <Modal open={confirmationModalOpen} onClose={() => setConfirmationModalOpen(false)}>
                <Modal.Header>
                    Færdiggør rapport
                </Modal.Header>
                <Modal.Content>
                    Er du sikker på, at du vil færdiggøre din rapport?
                    Når du afslutter rapporten, kan du ikke længere redigere den eller ændre dine svar.
                    Du bør kun gøre dette, hvis du har afsluttet alle årets opgaver og er klar til at påbegynde arbejdet med næste års rapport.
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='black'
                        content='Annullér'
                        onClick={() => setConfirmationModalOpen(false)}
                    />
                    <Button
                        primary
                        content='Færdiggør'
                        onClick={onConfirm}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

const InlineLogoPicker = ({ trigger, onLogoPicked, isLocked }) => {
    const [open, setOpen] = useState(false);
    const [startScrollY, setStartScrollY] = useState(null);

    useEffect(() => {
        if (!open) return;

        if (startScrollY === null) {
            setStartScrollY(window.scrollY);
            return
        }

        // close popup on scroll
        const onScrollChange = () => {
            const scrollDelta = Math.abs(window.scrollY - startScrollY);
            if (scrollDelta >= 750) {
                setOpen(false);
            }
        };

        window.addEventListener('scroll', onScrollChange);
        return () => window.removeEventListener('scroll', onScrollChange);
    }, [startScrollY, open]);

    return (
        <Popup
            basic
            disabled={isLocked}
            onClose={() => setOpen(false)}
            closeOnTriggerBlur={true}
            closeOnTriggerMouseLeave={false}
            closeOnDocumentClick={true}
            position='left center'
            trigger={
                <div onClick={() => setOpen(true)}>{trigger}</div>
            }
            open={open}
            content={
                <div
                    style={{ width: '500px' }}
                    onBlur={() => setOpen(false)}
                >
                    <UploadLogo
                        onLogoChanged={() => {
                            onLogoPicked();
                            setOpen(false);
                        }}
                    />
                </div>
            }
        />
    );
};

const IXBRLViewer = ({ value, yearReportData, regenerateDocument, factsBeingUpdated, productMetadata, id, paymentURL, executionError, values, onChange, hasPayed }) => {
    const [showExternalReport, setShowExternalReport] = useState(true);
    const [virtualHTMLDocument, setVirtualHTMLDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [yearReportDataExecutionStatus, setYearReportDataExecutionStatus] = useState('NOT_STARTED');
    const history = useHistory();

    const productIsLocked = values?.LOCK?.value?.isLocked || false;

    const documentGeneratorProductFact = productMetadata.facts[id];
    const yearReportDataTriggerFactID = documentGeneratorProductFact.options.yearReportTriggerFact;

    useEffect(() => {
        if (productIsLocked) {
            setYearReportDataExecutionStatus('DONE');
            return;
        }

        switch (yearReportDataExecutionStatus) {
            case 'NOT_STARTED': {
                if (factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus('EXECUTING');
                }
                break;
            }

            case 'EXECUTING': {
                if (!factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus('DONE');
                }
                break;
            }

            default: break; // no-op
        }
    }, [factsBeingUpdated, yearReportDataExecutionStatus, yearReportDataTriggerFactID, productIsLocked]);

    const inputHash = value?.inputHash;
    const virtualHTMLDocumentFileID = showExternalReport ? value?.virtualHTMLDocumentFileID : value?.internalReportVirtualHTMLDocumentFileID;
    const reportTemplateID = yearReportData?.data?.reportTemplateID;
    const reportTemplateVersion = yearReportData?.data?.reportTemplateVersion;

    useEffect(() => {
        if (executionError) {
            setError(true);
            setLoading(false);
            return;
        }

        if (yearReportDataExecutionStatus !== 'DONE') return;
        if (!virtualHTMLDocumentFileID) return;
        if (!reportTemplateID) return;
        if (!reportTemplateVersion) return;
        
        setError(false);

        fileStorage.getFileData(virtualHTMLDocumentFileID)
            .then((file) => {
                const bytes = Uint8Array.from(file.data.data);
                const rawJSON = new TextDecoder().decode(bytes);
                const virtualHTMLDocument = JSON.parse(rawJSON);
                setVirtualHTMLDocument(virtualHTMLDocument);
            })
            .catch(e => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));

    }, [virtualHTMLDocumentFileID, inputHash, reportTemplateID, reportTemplateVersion, factsBeingUpdated, yearReportDataExecutionStatus, executionError]);

    const handleDownloadIXBRL = async () => {
        setIsDownloading(true);
        setDownloadProgress(0);

        let ixbrlXHTML = value.rawIXBRL;

        const assetsPattern = new RegExp(reportEditor.getAssetURL('.+?'), 'g');

        const usedAssets = new Set();
        while (true) {
            const match = assetsPattern.exec(ixbrlXHTML);
            if (!match) break;
            usedAssets.add(match[0]);
        }

        let totalDownloaded = 0;

        await Promise.all([...usedAssets].map(async assetURL => {
            const assetResponse = await fetch(assetURL);
            if (!assetResponse.ok) {
                throw new Error(`Got ${assetResponse.status} fetching asset: ${assetURL}`);
            }

            const blob = await assetResponse.blob();
            const base64 = await readAsBase64(blob);

            const assetURLPattern = new RegExp(assetURL, 'g');

            ixbrlXHTML = ixbrlXHTML.replace(assetURLPattern, () => {
                return base64;
            });

            totalDownloaded++;

            setDownloadProgress(totalDownloaded / usedAssets.size * 100);
        }));

        downloadText('esg_report.xhtml', 'application/xhtml+xml', ixbrlXHTML);
    };

    const renderVirtualHTMLElement = virtualHTMLElement => {
        const {
            tag,
            style,
            children,
            ...other
        } = virtualHTMLElement;

        const props = {
            style,
            ...other,
        };

        if (virtualHTMLElement.class === 'purchaseLink') {
            props.onClick = () => history.push(paymentURL);
        }

        const convertedChildren = children.map(ele => {
            if (typeof ele === 'string') {
                return ele;
            }
            return renderVirtualHTMLElement(ele);
        });

        let element;
        if (convertedChildren.length === 0) {
            element = React.createElement(tag, props);
        } else {
            element = React.createElement(tag, props, convertedChildren);
        }

        if (virtualHTMLElement.class === 'emptyLogo') {
            return (
                <InlineLogoPicker
                    isLocked={productIsLocked}
                    trigger={element}
                    onLogoPicked={() => {
                        setYearReportDataExecutionStatus('NOT_STARTED');
                        regenerateDocument();
                    }}
                />
            );
        }
        
        return element;
    };

    const contentIsReady = !loading && !error && virtualHTMLDocument;

    const documentWrapper = (
        <Transition visible={contentIsReady} duration={500} animation='fade'>
            <div>
                <div style={{ all: 'initial' }} id='documentWrapper'>
                    {virtualHTMLDocument && renderVirtualHTMLElement(virtualHTMLDocument)}
                </div>
            </div>
        </Transition>
    );

    const updatedAtTimestamp = new Date(value?.updatedAt).toLocaleString('da-DK', {
        day: 'numeric', 
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    const taxReportedProductFact = Object.values(productMetadata.facts).find(fact => {
        return fact.tag === TAX_REPORTED_TAG;
    });

    let color;
    let icon;
    let text;
    if (loading) {
        color = 'grey';
        icon = 'spinner';
        text = 'Din rapport indlæses...';
    } else if (error) {
        color = 'red';
        icon = 'warning sign';
        text = 'Der opstod en fejl og din rapport kunne ikke indlæses...';
    } else {
        color = 'green';
        icon = 'check circle';
        text = 'Din rapport er ' + (productIsLocked ? 'færdig!' : 'klar');
    }

    return (
        <>
            <Segment color={color}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <Header>
                            <Icon
                                name={icon}
                                color={color}
                                loading={loading}
                            />
                            <Header.Content>
                                {text}
                                {contentIsReady && (
                                    <Header.Subheader>
                                        <b>Sidst opdateret:</b> {updatedAtTimestamp}
                                    </Header.Subheader>
                                )}
                            </Header.Content>
                        </Header>
                    </div>
                    {contentIsReady && (
                        <div style={{ display: 'flex', justifyContent: 'right', flexDirection: 'column', gap: '0.25em' }}>
                            <ReportOptionButton
                                content='Rapportindstillinger'
                                icon='cog'
                                onClick={() => setSettingsModalOpen(true)}
                                tooltip='Vælg logo, firmafarver og skrifttype'
                            />

                            <ReportOptionButton
                                onClick={() => {
                                    handleDownloadIXBRL()
                                        .catch(() => toast.error('Dokumentet kunne ikke downloades...'))
                                        .finally(() => setIsDownloading(false));
                                }}
                                content={isDownloading ? `Downloading: ${downloadProgress.toFixed(2)} %` : 'Download som iXBRL'}
                                icon={<Icon name={isDownloading ? 'spinner' : 'download'} loading={isDownloading} />}
                                disabled={isDownloading}
                                tooltip='Hent din rapport som en iXBRL-fil'
                            />

                            {value?.internalReportVirtualHTMLDocumentFileID && (
                                <ReportOptionButton
                                    icon='exchange'
                                    content={`Skift til ${showExternalReport ? 'intern' : 'ekstern'} rapport`}
                                    onClick={() => setShowExternalReport(!showExternalReport)}
                                />
                            )}

                            {taxReportedProductFact && !productIsLocked && hasPayed && (
                                <FinishReportButton
                                    onConfirm={() => {
                                        onChange(taxReportedProductFact.id, { boolean: true });
                                        console.log(taxReportedProductFact.id, onChange);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </Segment>
            {documentWrapper}
            {settingsModalOpen && (
                <ReportThemeSettingsModal
                    isLocked={productIsLocked}
                    onClose={() => setSettingsModalOpen(false)}
                    onSave={async () => {
                        if (productIsLocked) return;

                        setYearReportDataExecutionStatus('NOT_STARTED');
                        setVirtualHTMLDocument(null);
                        setLoading(true);
                        regenerateDocument();
                    }}
                />
            )}
        </>
    );
};

export default IXBRLViewer;
