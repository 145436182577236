import React, { useEffect, useMemo, useState } from 'react';
import CreditsExpirationDateInfo from '../PaymentFlowCredits/CreditsExpirationDateInfo';
import CreditsPuchaseForm from '../PaymentFlowCredits/CreditsPuchaseForm';
import { getCreditsExpirationDate, getCreditsPriceSteps } from 'http/credits';
import { Button, Loader, Message } from 'semantic-ui-react';
import CreditsIcon from 'design/atoms/CreditsIcon';
import { useCreditsContext } from 'design/molecules/CreditsProvider';
import usePersistedState, { clearPersistedState } from 'util/usePersistedState';
import useDeferredRedirect from 'util/useDeferredRedirect';
import { useRootUser } from 'util/useUser';
import { getParam } from 'util/QueryParams';
import HighFive from '../shared/HighFive';
import PaymentShell from '../shared/PaymentShell';
import PaymentPanels from '../shared/PaymentPanels';
import PaymentOverview from '../shared/PaymentOverview';
import usePaymentState from '../shared/usePaymentState';
import CreditsConfirmAccountantRole from '../PaymentFlowCredits/CreditsConfirmAccountantRole';

const CreditsPaymentFlowForAccountants = () => { 
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [priceSteps, setPriceSteps] = useState([]);
    const [expirationDate, setExpirationDate] = useState(null);
    const [selectedStepIdx, setSelectedStepIdx] = useState(0);

    const [HasConfirmedRole, setConfirmedRole] = useState(false);
    
    const credits = useCreditsContext();
    const rootUser = useRootUser();
    const creditsStorageKey = `${rootUser.id}.creditsToBuy`;
    const [creditsToBuy, setCreditsToBuy] = usePersistedState(creditsStorageKey, 0);


    useEffect(() => {
        Promise.all([
            getCreditsPriceSteps(true),
            getCreditsExpirationDate(),
        ])
            .then(([priceSteps, expirationDate]) => {
                setPriceSteps(priceSteps);
                setExpirationDate(expirationDate);
            })
            .catch(() => setHasError(true))
            .finally(() => setLoading(false));
    }, []);

    const orderLines = useMemo(() => {
        if (creditsToBuy === 0) {
            return [];
        }

        if (priceSteps.length === 0) {
            return [];
        }

        const { unitPrice } = [...priceSteps].reverse().find(({ minimumCreditsRequired }) => {
            return creditsToBuy >= minimumCreditsRequired;
        });

        const description = (
            <span>
                <CreditsIcon orange /> {creditsToBuy} credits × {unitPrice} kr.
            </span>
        )

        return [{
            description,
            price: unitPrice * creditsToBuy,
            metadata: {
                amountOfCredits: creditsToBuy,
            },
        }];
    }, [creditsToBuy, priceSteps]);

    const paymentState = usePaymentState({ orderLines });

    useDeferredRedirect(paymentState.paymentDone, getParam('redirect') || '/');

    const performPayment = async () => {
        const { success } = await paymentState.performPaymentRequest();
        if (success) {
            clearPersistedState(creditsStorageKey);
            await credits.refetch();
        }
    };

    const steps = [
        {
            title: 'Vælg pakke',
            icon: 'box',
            renderContent: () => (
                <CreditsPuchaseForm
                    creditsToBuy={creditsToBuy}
                    setCreditsToBuy={setCreditsToBuy}
                    priceSteps={priceSteps}
                />
            ),
            renderCallToAction: () => (
                <Button
                    primary
                    content='Gå til betaling'
                    fluid
                    icon='arrow right'
                    labelPosition='right'
                    disabled={creditsToBuy === 0 || (!HasConfirmedRole && credits.credits===0)}
                    onClick={() => setSelectedStepIdx(selectedStepIdx + 1)}
                />
            ),
        },
        {
            title: 'Betaling',
            icon: 'credit card outline',
            disabled: creditsToBuy === 0,
            renderContent: () => {
                return paymentState.renderPaymentMethod();
            },
            renderCallToAction: () => (
                <div>
                    {paymentState.renderPayButton(performPayment, (!HasConfirmedRole && credits.credits===0))}
                    {paymentState.renderError()}
                </div>
            ),
        },
    ];

    const renderContent = () => {
        console.log("CREDITS!!!!: ", credits.credits);
        if (loading) {
            return (
                <Loader
                    size='huge'
                    inline='centered'
                    active
                />
            );
        }
    
        if (hasError) {
            return (
                <Message
                    error
                    size='small'
                    icon='warning sign'
                    header='Indkøbskurven kunne ikke indlæses'
                />
            );
        }

        if (paymentState.paymentDone) {
            return (
                <HighFive isCreditsPurchase={true} />
            );
        }

        const selectedStep = steps[selectedStepIdx];

        const rightPanel = (
            <>
                <PaymentOverview
                    orderLines={orderLines}
                    extraContent={selectedStep.renderCallToAction()}
                />
                { credits.credits===0 &&<CreditsConfirmAccountantRole role={"Rapport & Erklæringer"} hasConfirmedRole={HasConfirmedRole} onConfirmRole={setConfirmedRole}/>}
                <CreditsExpirationDateInfo expirationDate={expirationDate} />
            </>
        );
        return (
            <PaymentPanels
                leftPanel={selectedStep.renderContent()}
                rightPanel={rightPanel}
            />
        );
    };

    return (
        <PaymentShell
            title='Køb credits'
            steps={steps}
            disabled={paymentState.working}
            selectedStepIdx={selectedStepIdx}
            onSelectedStepChange={setSelectedStepIdx}
            paymentFlowCompleted={paymentState.paymentDone}
            children={renderContent()}
        />
    );
};

export default CreditsPaymentFlowForAccountants;